import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/utils/enums';

interface IReferenceState {
  references: IReference[];
  status: Status;
  referenceSearchTerm: string;
}

const initialState: IReferenceState = {
  references: [],
  status: Status.Idle,
  referenceSearchTerm: '',
};

const referenceSlice = createSlice({
  name: 'references',
  initialState,
  reducers: {
    setReferences: (state, action: PayloadAction<IReference[]>) => {
      state.references = action.payload;
    },
    setReferencesStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setReferenceSearchTerm: (state, action: PayloadAction<string>) => {
      state.referenceSearchTerm = action.payload;
    },
  },
});

export const { setReferences, setReferencesStatus, setReferenceSearchTerm } =
  referenceSlice.actions;

export default referenceSlice.reducer;
