import { Link, useLocation } from 'react-router-dom';

import { cn } from 'src/lib/utils';

interface IHeaderTitles {
  containerStyle?: string;
  titleStyle?: string;
}

export default function HeaderTitles({ containerStyle, titleStyle }: IHeaderTitles) {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className={`flex ${containerStyle}`}>
      <div
        className={cn(
          'px-6 rounded font-verdana',
          titleStyle,
          currentPath.includes('about') && 'bg-yellow900 text-black'
        )}>
        <Link to='/about'>
          <div>ABOUT</div>
        </Link>
      </div>
      <div
        className={cn(
          'px-6 rounded font-verdana',
          titleStyle,
          currentPath === '/' && 'bg-yellow900 text-black'
        )}>
        <Link to='/'>
          <div>WORKS</div>
        </Link>
      </div>
      <div
        className={cn(
          'px-6 rounded font-verdana',
          titleStyle,
          currentPath.includes('references') && 'bg-yellow900 text-black'
        )}>
        <Link to='/references'>
          <div>REFERENCES</div>
        </Link>
      </div>
      <div
        className={cn(
          'px-6 rounded font-verdana',
          titleStyle,
          currentPath.includes('contact') && 'bg-yellow900 text-black'
        )}>
        <Link to='/contact'>
          <div>CONTACT</div>
        </Link>
      </div>
    </div>
  );
}
