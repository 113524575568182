import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from 'next-themes';
import CoverImage from 'src/components/CoverImage';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Skeleton } from 'src/components/ui/skeleton';
import { lowResImageUrlExporter } from 'src/helpers/lowResImageUrlExporter';
import { useAppSelector } from 'src/store/store';
import { Status } from 'src/utils/enums';

export default function References() {
  const referenceStatus = useAppSelector(state => state.references.status);
  const references = useAppSelector(state => state.references.references);
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('system');
  }, [setTheme]);

  return (
    <>
      <div
        className='grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 container'
        id='CoverImages'>
        {references.map((item, index) => {
          return (
            <CoverImage
              onPress={() => navigate(`/reference/${item.id}`)}
              key={item.id}
              image={item.image || ''}
              lowResImage={lowResImageUrlExporter(item.image || '')}
              title={item.id || ''}
              isConfidential={false}
            />
          );
        })}
        {(referenceStatus === Status.Loading || referenceStatus === Status.Idle) && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <AspectRatio ratio={16 / 9} key={index}>
                <Skeleton key={index} className='h-full w-full' />
              </AspectRatio>
            ))}
          </>
        )}
      </div>
    </>
  );
}
