import brandsSlice from './brandsSlice';
import referenceSlice from './referenceSlice';
import worksSlice from './worksSlice';

const reducers = {
  brands: brandsSlice,
  references: referenceSlice,
  works: worksSlice,
};

export default reducers;
