import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from 'src/components/ui/sheet';
import { deleteImage } from 'src/helpers/firebase/customStorageServices';
import { deleteDocumentService } from 'src/helpers/firebase/documentServices';
import { isFirebaseImage } from 'src/helpers/isFirebaseImage';
import { deleteBrandAction } from 'src/store/actions/brandsActions';
import { deleteReferenceAction } from 'src/store/actions/referenceActions';
import { deleteWorkAction } from 'src/store/actions/worksActions';

import { Button } from './ui/button';

export default function DeleteDocumentDrawer({
  type,
  data,
}: {
  type: string;
  data: IWork | IBrand | IReference;
}) {
  const handleDelete = async () => {
    switch (type) {
      case 'works':
        for (const image of (data as IWork).imageUrls || []) {
          if (!isFirebaseImage(image)) continue;
          await deleteImage(image);
        }

        if ((data as IWork).posterImage && isFirebaseImage((data as IWork).posterImage || '')) {
          await deleteImage((data as IWork).posterImage || '');
        }

        await deleteDocumentService('Works', data.id);
        deleteWorkAction(data.id);
        break;
      case 'brands':
        await deleteImage((data as IBrand).logoUrl || '');
        await deleteDocumentService('Brands', data.id);
        deleteBrandAction(data.id);
        break;
      case 'references':
        if (isFirebaseImage((data as IReference).image || '')) {
          await deleteImage((data as IReference).image || '');
        }
        await deleteDocumentService('References', data.id);
        deleteReferenceAction(data.id);
        break;
    }
  };

  return (
    <Sheet>
      <SheetTrigger className='w-full'>
        <div className='hover:bg-muted relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'>
          Delete
        </div>
      </SheetTrigger>
      <SheetContent side='bottom' className='w-screen flex flex-col justify-between items-center'>
        <SheetTitle>Warning!</SheetTitle>
        <SheetDescription>Are you sure you want to delete this document?</SheetDescription>
        <div />
        <div className='w-full flex flex-col gap-4'>
          <SheetClose onClick={handleDelete} className='w-full'>
            <Button variant='outline'>Delete</Button>
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
}
