import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { addImage, deleteImage } from 'src/helpers/firebase/customStorageServices';
import { setDocumentService } from 'src/helpers/firebase/documentServices';
import { isFirebaseImage } from 'src/helpers/isFirebaseImage';
import { updateReferenceAction } from 'src/store/actions/referenceActions';
import { z } from 'zod';

const FormSchema = z.object({
  text: z.string().min(1, { message: 'Text is required.' }),
});

export default function EditReferenceForm({ reference }: { reference: IReference }) {
  const [image, setImage] = useState<any>(reference.image);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      text: reference.text,
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'] },
    onDrop: acceptedFiles => {
      setImage(acceptedFiles[0]);
    },
  });

  const removeImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setImage(null);
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    if (!image) {
      toast.error('Please select an image');
      return;
    }

    let imageUrl = image;
    const isNewImage = image instanceof File;

    if (isNewImage && reference.image) {
      if (isFirebaseImage(reference.image)) {
        await deleteImage(reference.image);
      }
      imageUrl = await addImage('references/' + reference.id, image);
    }

    const newReference = {
      ...reference,
      ...data,
      image: imageUrl,
    };

    setDocumentService(reference.id, 'References', newReference).then(res =>
      toast.success('Reference updated')
    );
    updateReferenceAction(newReference);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-full space-y-6'>
        <div className='grid grid-cols-4 gap-10'>
          <div className='col-span-1'>
            <div
              {...getRootProps()}
              className='border-dashed mb-4 p-2 rounded border-spacing-2 border-2'>
              <input {...getInputProps()} />
              <p>{`Drag 'n' drop some files here, or click to select files`}</p>
            </div>
            {image && (
              <div>
                <img
                  width={640}
                  height={480}
                  src={image instanceof File ? URL.createObjectURL(image) : image}
                  alt='preview'
                />
                <Button variant='outline' className='my-2' onClick={removeImage}>
                  Remove
                </Button>
              </div>
            )}
          </div>
          <div className='col-span-3 flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='text'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Text</FormLabel>
                  <FormControl>
                    <Input placeholder='Enter text' {...field} />
                  </FormControl>
                  <FormDescription>This is the text of the reference.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex w-full justify-end'>
              <Button type='submit'>Submit</Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
