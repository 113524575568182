import { Sheet, SheetContent, SheetTitle, SheetTrigger } from 'src/components/ui/sheet';

import AddBrandForm from './AddBrandForm';
import AddReferenceForm from './AddReferenceForm';
import AddWorkForm from './AddWorkForm';
import { Button } from './ui/button';

export default function AddDocumentDrawer({ type }: { type: string }) {
  const getForm = () => {
    switch (type) {
      case 'Works':
        return <AddWorkForm />;
      case 'Brands':
        return <AddBrandForm />;
      case 'References':
        return <AddReferenceForm />;
      default:
        return null;
    }
  };

  return (
    <Sheet>
      <SheetTrigger className=''>
        <Button variant='default'>Add new {type.slice(0, -1)}</Button>
      </SheetTrigger>
      <SheetContent
        side='bottom'
        className='w-screen flex flex-col items-center max-h-screen overflow-y-auto'>
        <SheetTitle>You are adding document </SheetTitle>
        <div>{type}</div>
        {getForm()}
      </SheetContent>
    </Sheet>
  );
}
