import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from 'next-themes';
import CoverImage from 'src/components/CoverImage';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Skeleton } from 'src/components/ui/skeleton';
import { lowResImageUrlExporter } from 'src/helpers/lowResImageUrlExporter';
import { useAppSelector } from 'src/store/store';
import { Status } from 'src/utils/enums';

export default function Home() {
  const workStatus = useAppSelector(state => state.works.status);
  const works = useAppSelector(state => state.works.works);
  const brands = useAppSelector(state => state.brands.brands);
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('system');
  }, [setTheme]);

  return (
    <>
      {/* <div onClick={() => toggleTheme()} className='h-10 w-10 bg-red-400'></div> */}
      <div className='grid grid-flow-row sm:grid-cols-2 gap-2 container' id='CoverImages'>
        {works.map((item, index) => {
          const brand = brands.find(brand => brand.id === item.brand.id);
          const image = item.posterImage || item.imageUrls?.[0] || '/AlestaPlaceholder.png';
          return (
            <CoverImage
              brandImage={brand?.logoUrl}
              onPress={() => navigate(`/work/${item.id}`)}
              isConfidential={item.isConfidential || false}
              key={item.id}
              image={image}
              lowResImage={lowResImageUrlExporter(image)}
              title={item.title || ''}
            />
          );
        })}
        {(workStatus === Status.Loading || workStatus === Status.Idle) && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <AspectRatio ratio={16 / 9} key={index}>
                <Skeleton key={index} className='h-full w-full' />
              </AspectRatio>
            ))}
          </>
        )}
      </div>
    </>
  );
}
