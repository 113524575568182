import { getDocumentsService } from 'src/helpers/firebase/documentServices';
import { Status } from 'src/utils/enums';

import {
  setReferenceSearchTerm,
  setReferences,
  setReferencesStatus,
} from '../slices/referenceSlice';
import { store } from '../store';

const dispatch = store.dispatch;

export const loadReferencesAction = async () => {
  dispatch(setReferencesStatus(Status.Loading));
  try {
    const references: IReference[] = await getDocumentsService('References');
    dispatch(setReferences(references));
    dispatch(setReferencesStatus(Status.Success));
  } catch (error) {
    dispatch(setReferencesStatus(Status.Error));
  }
};

export const addReferenceAction = async (reference: IReference) => {
  try {
    const newReferences = [...store.getState().references.references, reference];
    dispatch(setReferences(newReferences));
    return newReferences;
  } catch (error) {
    throw error;
  }
};

export const updateReferenceAction = async (reference: IReference) => {
  try {
    const newReferences = store
      .getState()
      .references.references.map(item => (item.id === reference.id ? reference : item));
    dispatch(setReferences(newReferences));
    return newReferences;
  } catch (error) {
    throw error;
  }
};

export const deleteReferenceAction = async (referenceId: string) => {
  try {
    const newReferences = store
      .getState()
      .references.references.filter(item => item.id !== referenceId);
    dispatch(setReferences(newReferences));
    return newReferences;
  } catch (error) {
    throw error;
  }
};

export const setReferenceSearchTermAction = (searchTerm: string) => {
  dispatch(setReferenceSearchTerm(searchTerm));
};
