import { useNavigate, useParams } from 'react-router-dom';

import { IconCircleArrowLeft } from '@tabler/icons-react';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Skeleton } from 'src/components/ui/skeleton';
import { useAppSelector } from 'src/store/store';
import { Status } from 'src/utils/enums';

export default function ReferencePage() {
  const { referenceId } = useParams();
  const navigate = useNavigate();
  const references = useAppSelector(state => state.references.references);
  const reference = references.find(item => item.id === referenceId);
  const referenceStatus = useAppSelector(state => state.references.status);

  if (referenceStatus === Status.Loading || referenceStatus === Status.Idle) {
    return (
      <>
        <div className='flex flex-col gap-10'>
          <div className='work-container'>
            <Skeleton className='h-96 w-full' />
          </div>
          <Skeleton className='h-44 w-full' />
        </div>
      </>
    );
  }

  return (
    <>
      <div className='flex flex-col gap-10'>
        <div className='work-container'>
          <IconCircleArrowLeft
            onClick={() => navigate('/references')}
            className='cursor-pointer'
            size={32}
          />
        </div>
        <div className='work-container'>
          <AspectRatio ratio={16 / 9}>
            <img
              src={reference?.image}
              alt={reference?.text}
              className='w-full h-full object-contain'
            />
          </AspectRatio>
        </div>
        <div className='work-container'>{reference?.text}</div>
      </div>
    </>
  );
}
