import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Footer from 'src/layouts/Footer';
import Header from 'src/layouts/Header';
import About from 'src/pages/about';
import Admin from 'src/pages/admin';
import AdminPage from 'src/pages/admin/page';
import Contact from 'src/pages/contact';
import ReferencePage from 'src/pages/reference/page';
import References from 'src/pages/references';
import WorkPage from 'src/pages/work/page';
import Works from 'src/pages/works';

const App = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div>
      {!isAdminRoute && <Header />}
      <Routes>
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/admin' element={<Admin />} />
        <Route path='/admin/:categorie' element={<AdminPage />} />
        <Route path='/' element={<Works />} />
        <Route path='/work/:workId' element={<WorkPage />} />
        <Route path='/references' element={<References />} />
        <Route path='/reference/:referenceId' element={<ReferencePage />} />
      </Routes>
      {!isAdminRoute && <Footer />}
    </div>
  );
};

export default function RootApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
