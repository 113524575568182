import { Sheet, SheetContent, SheetTitle, SheetTrigger } from 'src/components/ui/sheet';

import EditBrandForm from './EditBrandForm';
import EditReferenceForm from './EditReferenceForm';
import EditWorkForm from './EditWorkForm';

export default function EditDocumentDrawer({
  type,
  data,
}: {
  type: string;
  data: IWork | IBrand | IReference;
}) {
  const getForm = () => {
    switch (type) {
      case 'Works':
        return <EditWorkForm work={data as IWork} />;
      case 'Brands':
        return <EditBrandForm brand={data as IBrand} />;
      case 'References':
        return <EditReferenceForm reference={data as IReference} />;
      default:
        return null;
    }
  };

  return (
    <Sheet>
      <SheetTrigger className='w-full'>
        <div className='hover:bg-muted relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'>
          Edit
        </div>
      </SheetTrigger>
      <SheetContent
        side='bottom'
        className='w-screen flex flex-col items-center max-h-screen overflow-y-auto'>
        <SheetTitle>You are editing document</SheetTitle>
        {getForm()}
      </SheetContent>
    </Sheet>
  );
}
